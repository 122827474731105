.theme-button {
    background: #3A4DFD;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
}

.distributionButton {
    padding-top: 8px;
    text-decoration: none;
}

.distributionButton:hover {
    color: white;
}


.cancelButton,
.nextButton {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    position: fixed;
    bottom: 16px;
}

.nextButton {
    right: 30px;
}

.cancelButton {
    left: 280px
}

.btn-primary {
    background-color: #3A4DFD !important;
    border-color: #3A4DFD !important;
}

.btn-outline-primary {
    border-color: #3A4DFD !important;
    color: #3A4DFD !important;
}

.btn-outline-primary:hover,
.btn.show {
    background-color: white !important;
}

.btn-default {
    background: #3A4DFD;
    color: white;
    border-radius: 5px;
    min-width: 170px;
    padding-inline: 30px;
    height: 45px;
}

.btn-outline {
    background: #FFFFFF;
    border: 1px solid #3A4DFD;
    color: #3A4DFD;
    padding-inline: 4rem;
    text-decoration: none;
    border-radius: 5px;
}

.btn-previous {
    background-color: white;
    min-width: 170px;
    padding-inline: 30px;
    height: 45px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500 !important;
    box-shadow: 4px 4px 0px rgb(0 0 0 / 25%);
    position: fixed;
    bottom: 16px;
}