.sidebar {
    background: #363740;
    height: 100vh;
    width: 250px;
    overflow: auto;
    position: fixed;
}

.sidebar-footer{
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: #43444d;
    padding: 10px;
    font-size: 12px;
}

.sidebar-footer a{
    color: #a3a3a3 !important;
}


.left-sidebar{
    margin-left: 250px;
}

.hamburger {
    height: 24px;
    width: 24px;
}

.logo {
    height: 25px;
    width: 85%;
}

.logoText {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: white;
    padding-left: 7px;
    margin-bottom: 0;
}

.navIcon {
    height: 20px;
    width: 20px;
}

.navText {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
}

.active {
    background-color: #43444d;
}

.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 3px;
    height: 54px;
    background: white !important;
}

.navLink:hover {
    background-color: #43444d;
}

/* PATH */

.pathText,
.headingText {
    cursor: pointer;
}

.headingText {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #282632;
    margin-bottom: 6px;
}

.pathText {
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    margin-bottom: 4px;
    color: #3A4DFD;
}

.pathText:last-child {
    color: black;
    cursor: default;
}

/* HEADER */

.header {
    display: flex;
    justify-content: space-between;
}

.header-search-icon {
    height: 20px;
    width: 20px;
}

.notificationIcon {
    height: 20px;
    width: auto;
    padding-left: 25px;
    padding-right: 18px;
}

.divider {
    height: 28px;
    background: #3A4DFD;
    width: 3px;
}

.userName {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #3A4DFD;
    margin-bottom: 0;
}

.userStatus {
    font-weight: 400;
    font-size: 9px;
    line-height: 14px;
    color: #282632;
    margin-bottom: 0;
}