.background {
    width: 170px;
    height: auto;
}

.opacity-50 {
    opacity: 0.5;
}

.report-drpd {
    max-height: 150px !important;
    overflow-y: auto !important;
}