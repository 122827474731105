.createDistributionImg {
    width: 300px;
    height: 250px;
    position: fixed;
    bottom: 30px;
    right: 80px;
}

.distributionContainer {
    padding-top: 100px;
}

.createDistributionTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    margin-bottom: 50px;
}

.distributionLabel {
    font-size: 16px;
    line-height: 30px;
}

.distributionInput {
    border: 1px solid #D9D9D9;
    outline: none;
    padding: 5px 0 6px 12px;
    width: 450px;
    font-size: 16px;
    line-height: 30px;
}


.lastModifiedDate {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #282632;
}

.lastModifiedTime {
    font-weight: 400;
    font-size: 9px;
    line-height: 14px;
    color: #282632;
}

.distributeImage {
    height: 180px;
    width: 180px;
    cursor: pointer;
}

.distributeMethod {
    position: fixed;
    bottom: 0;
    right: 23px;
    height: 210px;
    width: auto;
}

.description {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 60px;
    padding-top: 60px;
}

/* Schedule Distribution */

.frequencyTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 14px;
}

.frequency {
    display: flex;
    align-items: center;
    padding-left: 40px;
}

.frequency label {
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
    padding-left: 20px;
}

.scheduleCard {
    background: #ffffff;
    border: 3px solid #d7d7d7;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 280px;
    width: 500px;
    margin-left: 20px;
    padding: 20px;
}

.inputTime {
    width: 110px;
    border: 2px solid #d7d7d7;
    border-radius: 12px;
    margin-right: 10px;
    padding: 6px 12px;
    color: #7a7a7a;
}

.inputTime::-webkit-datetime-edit-ampm-field {
    display: none;
}

input[type="time"]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    margin: -10px;
}

.label {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    padding-bottom: 16px;
}

.schedule-dropdown {
    border: 2px solid #d7d7d7;
    border-radius: 12px;
    background: white;
    outline: none;
    padding: 6px 12px;
    appearance: none;
    padding-right: 24px;
    background: url("../../assets/images/blue-down_arrow.png") no-repeat;
    background-size: 10px;
    background-position: calc(100% - 8px);
}

.timeZoneContainer {
    padding-top: 40px;
}

/* Draft Email */

.draftContainer {
    padding-top: 40px;
}

.topRight {
    position: fixed;
    top: 112px;
    right: 46px;
    opacity: 0.5;
    height: 150px;
    width: auto;
}
.bottomLeft {
    position: fixed;
    bottom: 60px;
    left: 290px;
    opacity: 0.5;
    height: 150px;
    width: auto;
}

.heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    padding-block: 10px;
}


.draft-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 4px;
}

.emailInput {
    width: 100%;
    padding-left: 35px;
}

.draft-input {
    padding-left: 10px;
}

.draft-input,
.emailInput {
    outline: none;
    background: #FAFAFA;
    border: 1px solid #CECECE;
    border-radius: 5px;
    padding-block: 5px;
}

.icon {
    position: absolute;
    left: 12px;
    top: 10px;
}

.DraftEditor-editorContainer {
    height: 150px !important;
    padding: 10px !important;
}

/* Finalize */

.finalizeContainer {
    padding-top: 30px;
}

.radioButtons {
    padding-block: 20px;
}

.excelRadio {
    padding-right: 130px;
}

.radioLabel {
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    padding-left: 16px;
}

.inputLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
}

.finalizeInput {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    width: 500px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    outline: none;
    padding: 2px 8px;
}

.finalizeImage {
    position: fixed;
    right: 50px;
    bottom: 0;
    z-index: -9;
    height: 290px;
    width: auto;
}

.contactUsImage {
    position: fixed;
    right: 50px;
    bottom: 0;
    z-index: -9;
    height: 290px;
    width: auto;
}

.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 60px;
    margin-bottom: 0;
}

/* Review */

.input {
    outline: none;
    background: #F1F1F1;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 400px;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    padding: 2px 12px;
}

.reviewImage {
    position: fixed;
    bottom: 60px;
    right: 15px;
    height: 260px;
    width: auto;
}

.review-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    padding-right: 20px;
}