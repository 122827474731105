.rowsPerText,
.recordsPerText,
.viewPageText {
    font-size: 12px;
    line-height: 18px;
    color: #282632;
}

.rowsPerText,
.recordsPerText {
    font-weight: 500;
}

.rowsPerText {
    padding-left: 10px;
}

.recordsPerText {
    margin-right: 58px;
}

.viewPageText {
    font-weight: 400;
}

.paginationBorder {
    border-top: 1px solid #d9d9d9;
}

.pageDropdown {
    border: none;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    background: url('../../../assets/images/blue-down_arrow.png') no-repeat right;
    outline: none;
    background-size: 10px;
    width: 30px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}

.accountName {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #3A4DFD;
}

.companyName {
    font-weight: 400;
    font-size: 9px;
    line-height: 14px;
    color: #282632;
}