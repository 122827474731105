.login-button {
    background: #3A4DFD;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
}


.login-button:hover {
    background: #0d208e !important;
    color: #FFFFFF !important;
}


.or{
    padding: 14px;
    background: white;
    margin: 25px;
    /* width: 30px; */
    border-radius: 40px;
    color: #040404;
    font-weight: bold;
    box-shadow: 2px 2px 11px 12px #f3f1f1;
}

.inutuit-button{
    background: #0277c4 !important;
    font-weight: 600;
}

.inutuit-button:hover{
    background: #0277c4 !important;
}

.login-with-intuit-button{
    background: url(./Sign_in_blue_btn_med_default.svg) no-repeat 0 0;
    height: 38px;
    cursor: pointer;
    width: 163px;
    margin: auto;
}

.login-with-intuit-button:hover{
    background: url(./Sign_in_blue_btn_med_hover.svg) no-repeat 0 0;
}