
.main-success {
    height: calc(100vh - 100px);
}

.success-image {
    height: 275px;
    width: auto;
}

.export-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 80px;
}

.export-description {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
}

/* WORK IN PROGRESS ! */

@keyframes prgs {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.is-loading {
    animation: prgs;
    animation-duration: 10s;
}

.is-done {
    transition: width 1s;
    width: 100% !important;
}

.main-progress {
    height: calc(100vh - 200px);
}

.wrapper {
    background: #ffffff;
    border: 1px solid #a2a2a2;
    border-radius: 20px;
    height: 28px;
    width: 800px;
}

.bar {
    background-color: #3a4dfd;
}

.progress-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
}

/* PAGE TITLE */

.page-title-bg {
    width: 228px;
    height: auto;
}

.page-title-description {
    font-weight: 700;
    font-size: 16px;
    line-height: 33px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    margin-left: -215px;
}