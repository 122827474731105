@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
    background-color: #f7f7fc !important;
}

/* CONTAINER */
@media (min-width: 1024px) {
    .container {
        max-width: 980px !important;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1120px !important;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1270px !important;
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1460px !important;
    }
}

/* PADDING-LEFT */
.ps-2_5 {
    padding-left: 0.75rem;
}

.ps-4_1 {
    padding-left: 1.75rem !important;
}

.ps-4_2 {
    padding-left: 2rem;
}

/* PADDING-BOTTOM */
.pb-2_5 {
    padding-bottom: 0.75rem;
}

.pb-3_5 {
    padding-bottom: 1.25rem;
}

.pb-4_1 {
    padding-bottom: 1.75rem;
}

/* PADDING-RIGHT */

.pe-4_1 {
    padding-right: 1.75rem !important;
}

.pe-4_2 {
    padding-right: 2rem;
}

/* PADDING-RIGHT */
.pt-2_5 {
    padding-top: 0.75rem;
}

.pt-3_5 {
    padding-top: 1.25rem;
}

.pt-4_2 {
    padding-top: 2rem;
}

/* PADDING INLINE */
.px-2_5 {
    padding-inline: 0.75rem;
}

/* PADDING BLOCK */
.py-4_2 {
    padding-block: 2rem;
}

/* MARGIN LEFT */
.ms-250px {
    margin-left: 250px;
}

.ms-3_5 {
    margin-left: 1.25rem;
}

/* MARGIN RIGHT */

.me-3_5 {
    margin-right: 1.25rem;
}

/* HEIGHT & WIDTH */

.max-h-150px {
    max-height: 150px;
}

.w-32 {
    width: 32%;
}

.w-100p {
    width: 100% !important;
}

.w-55 {
    width: 55%;
}

.w-20 {
    width: 20% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.dropdown-toggle::after {
    vertical-align: 1px !important;
    margin-left: 4px !important;
    border-top: .3em solid #3A4DFD;
}


/* DATA TABLE */
.rdt_TableCol_Sortable span {
    color: #3A4DFD !important;
}

.text-theme {
    color: #3A4DFD !important;
}

.rdt_TableRow {
    padding-block: 14px;
}

.rdt_TableRow:nth-child(even) {
    background: #f1f3ff !important;
}

tbody>tr:nth-of-type(odd)>* {
    background-color: #f1f3ff !important;
}

.bg-white {
    background-color: #fff !important;
}

.rdt_TableCol_Sortable {
    overflow: unset !important;
}

.rdt_TableCol_Sortable span {
    opacity: unset;
}

/* .rdt_TableCol:last-child {
    justify-content: center;
} */

.white-space-normal {
    white-space: normal !important;
}

.fs-11px {
    font-size: 11px !important;
}

.fs-12px {
    font-size: 12px !important;
}

/* .rdt_TableBody {

} */
.checkbox {
    display: block;
    position: relative;
    margin-bottom: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #3A2FF2;
    border-radius: 15px;
}

.checkbox input:checked~.checkmark {
    background-color: #3A4DFD;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked~.checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* div:has(div):has(.rdt_Table) {
    overflow-x: unset;
    overflow-y: unset;
} */
/* CUSTOM SCROLLBAR */

/* width */
::-webkit-scrollbar {
    width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3A4DFD;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3A2FF2;
}

.shadow {
    filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.25));
}

.tableContainer {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
}

.searchInput {
    border: 1px solid #D9D9D9;
    border-radius: 7px;
    color: #878787;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
}

.searchInput:focus-visible {
    outline: none;
}

.searchIcon {
    height: 15px;
    width: 15px;
    position: absolute;
    left: 11px;
    top: 12px;
}

.active-tab {
    border-bottom: 7px solid #3A4DFD;
    font-weight: 500 !important;
}

.tab {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    padding-bottom: 6px;
    padding-inline: 32px;
    background-color: white !important;
}

.pointer {
    cursor: pointer;
}

.bottom-fixed {
    position: fixed;
    bottom: 20px;
}

.me-150px {
    margin-right: 150px;
}

/* Radio Button  */

input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    width: 22px;
    height: 22px;
    border: 2px solid #D9D9D9;
    border-radius: 50%;
    outline: none;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 22%;
    border-radius: 50%;
}

input[type='radio']:checked:before {
    background: #3A4DFD;
}


.required {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #FF0000;
    margin-inline: 5px;
}

input[type="date"] {
    color: #7A7A7A;
    border: none;
    outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('./assets/images/distributions/calender.png') no-repeat;
    height: 18px;
    background-size: contain;
    border-width: thin;
}

.w-58 {
    width: 58%;
}

.w-400px {
    width: 400px;
}

/* DROPDOWN */
.custom-dropdown-menu {
    border: none;
    outline: none;
    border: 1px solid #3A4DFD;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    background: #FFFFFF;
    inset: 14px auto auto 0px !important;
}

.dropdown-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #282632;
}

.dropdown-link:hover {
    color: #282632 !important;
}

.fa-spin {
    color: #3A4DFD !important;
}

.flex-1 {
    flex: 1 !important;
}

.h-100-vh {
    height: 100vh !important;
}

/* DEFAULT */

.main {
    height: 100vh;
    display: flex;
    overflow: hidden !important;
}

.content-container {
    height: calc(100vh - 60px);
}

.table-responsive {
    height: calc(100vh - 400px);
    overflow-y: scroll;
}

.my-auto {
    margin-block: auto !important;
}

.table thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    white-space: nowrap;
}

td {
    font-size: 12px;
    vertical-align: middle !important;
}

.distributionTab {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    padding-bottom: 6px;
    padding-inline: 32px;
}

.sortable::after {
    display: inline-block;
    cursor: pointer;
    margin-left: 7px;
    vertical-align: 2px;
    content: "";
    border-top: .3em solid #3A4DFD;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.sortable-desc::after {
    display: inline-block;
    cursor: pointer;
    margin-left: 7px;
    vertical-align: 2px;
    content: "";
    border-bottom: .3em solid #3A4DFD;
    border-right: .3em solid transparent;
    border-top: 0;
    border-left: .3em solid transparent;
}

.calender-menu {
    max-height: 35vh;
    overflow-y: auto;
}


a {
    text-decoration: none !important;
}

.enable-page-scrolling{
    overflow: auto !important;
}



.quickbooks-button{
    width: 223px;
    background: #2aa01d;
    border: none;
    padding: 6px;
    border-radius: 5px;
    color: white;
    font-weight: 500;

}


.disconnecqb{
    color: #3a4dfc;
    text-decoration: underline;
    cursor: pointer;
}

.h-100vh{
    height: 100vh;
}